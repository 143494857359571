import React, { useEffect } from 'react';
import clsx from 'clsx';
import { CardColor } from 'components/CardFlow/Customize/cardVersions';
import Button from 'components/Button';
import { useNavigate } from 'hooks/useNavigate';
import { RoutePath } from 'enums/Routes';
import { ReactComponent as GoldCard } from 'images/cards/gold-card-front.svg';
import { ReactComponent as SilverCard } from 'images/cards/light-purple-card-front.svg';
import { ReactComponent as PurpleCard } from 'images/cards/purple-silver-card-front.svg';
import { ReactComponent as CheckIcon } from 'images/check-icon.svg';
import { useSelector } from 'react-redux';
import { getCardData } from 'selectors/getCardData';
import { getApplicationData } from 'selectors/getApplicationData';
import Loader from 'components/Loader';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getCardDataForLoanApplication } from 'thunks';
import useReferralProgram from 'components/CardFlow/ReferralProgram/useReferralProgram';
import GiftCount from 'components/Card/GiftsCount/GiftCount';
import { getFullName } from 'handlers/yourName';

import styles from './ReferralProgramSummary.module.scss';

const getCardPreview = (cardText: string, cardColor?: CardColor) => {
  let selectedCard;
  let cardNameStyle;
  switch (cardColor) {
    case CardColor.Gold:
      selectedCard = <GoldCard className={styles.card} />;
      cardNameStyle = styles.gold;
      break;
    case CardColor.PurpleSilver:
      selectedCard = <SilverCard className={styles.card} />;
      cardNameStyle = styles.silver;
      break;
    case CardColor.LightPurple:
      selectedCard = <PurpleCard className={styles.card} />;
      cardNameStyle = styles.purple;
      break;
    default:
      selectedCard = <GoldCard className={styles.card} />;
      cardNameStyle = styles.gold;
      break;
  }
  return (
    <div className={styles.cardPreviewContainer}>
      <div className={styles.cardPreview}>{selectedCard}</div>
      <p className={clsx(styles.cardText, cardNameStyle)}>{cardText}</p>
    </div>
  );
};

const PlanneryCardSummary = () => {
  const navigate = useNavigate();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { application } = useSelector(getApplicationData);
  const {
    applied: cardApplied,
    fetched,
    cardColor,
    waitListPosition,
    borrowerCredentials: credentials,
    isLoading,
  } = useSelector(getCardData);

  const { rewards, nextReward, thanksLabel, credits } = useReferralProgram();
  const { borrowerFirstName: firstName, borrowerLastName: lastName } = application!;

  const cardText = `${getFullName(firstName || '', lastName || '')}, ${credentials}`;

  const handleClick = () => {
    navigate(RoutePath.ReferralProgram);
  };

  useEffect(() => {
    if (application?.id && !fetched && cardApplied) {
      dispatchWithUnwrap(getCardDataForLoanApplication(application?.id));
    }
  }, [application?.id, fetched, cardApplied]);

  if (isLoading) {
    return (
      <div className={clsx(styles.container, styles.loader)}>
        <Loader color="#795af7" />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <p className={styles.title}>Plannery Card</p>

      <p className={styles.subtitle}>
        You're {waitListPosition ? <span className={styles.bold}>#{waitListPosition}</span> : 'already'} on the
        waitlist.
      </p>

      {getCardPreview(cardText, cardColor)}

      <p className={styles.thanksLabel}>{thanksLabel}</p>

      <div className={styles.stepperContainer}>
        {credits > 3 ? (
          <GiftCount credits={credits} rewards={rewards} />
        ) : (
          rewards.slice(0, 3).map((reward, index) => (
            <div
              key={`${reward.id}-${index}`}
              className={clsx(styles.stepperItem, {
                [styles.rewardActive]: nextReward?.id === reward.id,
                [styles.rewardCompleted]: credits >= index + 1,
              })}
            >
              <div className={styles.iconContainer}>{credits >= index + 1 ? <CheckIcon /> : reward.icon}</div>
            </div>
          ))
        )}
      </div>
      <Button className={styles.button} onClick={handleClick}>
        Refer another friend
      </Button>
    </div>
  );
};

export default PlanneryCardSummary;
